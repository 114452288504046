// Tracking HTML attributes documented in README.md

import DoxAnalytics from "@dox/analytics";

const doxAnalytics = (window.doxAnalytics = new DoxAnalytics());

window.initAnalytics = ({ release, userId, page }) => {
  doxAnalytics.init({
    application: "doximity/opmed",
    release
  });

  if (userId) {
    doxAnalytics.identify({ userId });
  }

  doxAnalytics.page({ product: "news", view: `opmed_${page}` });
};

document.addEventListener("DOMContentLoaded", () => {
  $('[data-track-section]').each((_, sectionElement) => {
    const sectionName = $(sectionElement).data('track-section');
    const section = doxAnalytics.registerSection({ product: 'news' });
    $(sectionElement).find('[data-track-impression]').each((_, impressionElement) => {
      const impressionData = $(impressionElement).data();
      const impressionName = impressionData.trackImpression;
      const eventNamePrefix = `opmed_${sectionName}_${impressionName}`;
      const impression = section.registerImpression();
      const impressionProperties = {};
      if (impressionData.trackArticleExternalId) {
        impressionProperties['article_external_id'] = impressionData.trackArticleExternalId;
      }
      if (impressionData.trackAuthorExternalId) {
       impressionProperties['author_external_id'] = impressionData.trackAuthorExternalId;
      }
      if (impressionData.trackContext) {
        impressionProperties['context'] = impressionData.trackContext;
      }
      if (impressionData.trackCategoryName) {
        impressionProperties['category_name'] = impressionData.trackCategoryName;
      }
      if (impressionData.trackPageNumber) {
        impressionProperties['page_number'] = impressionData.trackPageNumber;
      }
      if (impressionData.trackProfileUrl) {
        impressionProperties['profile_url'] = impressionData.trackProfileUrl;
      }
      if (impressionData.trackShown != undefined) {
        impression.trackShown(eventNamePrefix, impressionElement, impressionProperties);
      }
      if (impressionData.trackTapped != undefined) {
        impression.trackTapped(eventNamePrefix, impressionElement, impressionProperties, { trackShownFully: true });
      }
    });
  });
});
